import { freeEmailDomains } from 'free-email-domains-typescript';
import { EDIT_PAGE } from '../../models/account-manager.interfaces';
import { RealmFeatures, ServiceUserAttribute } from '../../models/configuration.interfaces';

export const firstLetterUpperCase = (str?: string): string => {
  return str ? str[0].toUpperCase() + str.slice(1).toLowerCase() : '';
};

export const firstLetterUpperCaseName = (str?: string): string => {
  if (str) {
    return str
      .split(' ')
      .map((word) => {
        return word
          .split('-')
          .map((subWord) => {
            return firstLetterUpperCase(subWord);
          })
          .join('-');
      })
      .join(' ');
  }

  return '';
};

export const getConfAttributeNameFromPage = (page: EDIT_PAGE): keyof ServiceUserAttribute | keyof RealmFeatures => {
  switch (page) {
    case EDIT_PAGE.ADDRESS:
      return 'address';
    case EDIT_PAGE.NATIONAL_ID:
      return 'nationalId';
    case EDIT_PAGE.PHONE:
      return 'phoneNumber';
    case EDIT_PAGE.DELETE_ACCOUNT:
      return 'accountDeletion';
    default:
      break;
  }
};

export const emitActionDone = (): void => {
  if (document) {
    const event = new Event('onActionDone');
    document.dispatchEvent(event);
  }
};
/**
 * Check if it's a public email domain
 *
 * @param email
 */
export const isPublicEmail = (email: string): boolean => {
  return freeEmailDomains.includes(email.split('@')[1]);
};
