'use client';
import { Box, ChakraProvider, Flex } from '@chakra-ui/react';
import { Header, SvgSpriteProvider, Text, theme } from '@pluxee-design-system/core';
import Image from 'next/image';
import React from 'react';
import { GlobalContentLocalizedContent } from '../../../services/localization/interfaces';
import Footer from '../../components/footer/footer.pluxee';
import NextHeader from '../../components/next-header/next-header';

export default function PluxeeError({
  message,
  reason,
  reqId,
  text,
}: {
  message?: string;
  reason?: string;
  reqId?: string;
  text: string;
}): JSX.Element {
  const globalContent = {
    privacy_policy: 'Privacy Policy',
    cookie_policy: 'Cookie Policy',
    privacy_cookies: 'Privacy & Cookies',
    version: 'version',
  } as GlobalContentLocalizedContent;
  const footerUrl = {
    privacy:
      'https://assets.contentstack.io/v3/assets/bltb623f73452ceb473/blta6ee6b3216eac00f/65d36d465b87e7342b59e0ed/EN_Pluxee_Connect_Privacy_Policy_16_02_2024.pdf',
    cookies:
      'https://assets.contentstack.io/v3/assets/bltb623f73452ceb473/blt6b1138b1ebd7bdd2/65d36d460b928c15ae7cfde0/EN_Pluxee_Connect_Cookie_Policy_16_02_2024.pdf',
  };

  return (
    <ChakraProvider theme={theme}>
      <SvgSpriteProvider src='/images/pluxee/sprite.svg'>
        <NextHeader isPluxee={true} title='Sorry' />
        <Flex id='pluxee' backgroundColor='core.deepBlue.1' flexDir='column' justify='space-between'>
          <Header navItems={[]} withoutLoginButton />
          <Flex alignItems='center' flexDir='column' gap={6} justify='space-between'>
            <Flex alignItems='center' gap={3}>
              <Image src='/images/pluxee/plx-X-Mark-salmon-42x.svg' alt='X' width={42} height={42} />
              <Text variant='heroTitle.desktopMediumBlack'>Sorry!</Text>
            </Flex>
            <Image src='/images/pluxee/not-found.svg' alt='telescope' width={321} height={250} />
            <Text variant='subtitle.desktopMain'>{text}</Text>
            {reqId && <Text variant='subtitle.desktopMain'>{reqId}</Text>}
            <Box>
              {reason && <Text variant='subtitle.desktopMain'>Reason : {reason}</Text>}
              {message && (
                <Text variant='subtitle.desktopMain' fontWeight='semibold'>
                  {message}
                </Text>
              )}
            </Box>
          </Flex>
          <Footer globalContent={globalContent} footerUrl={footerUrl} />
        </Flex>
      </SvgSpriteProvider>
    </ChakraProvider>
  );
}
