// Account manager pages
export enum EDIT_PAGE {
  ADDRESS = 'address',
  APPLICATION = 'apps-and-services',
  BIRTHDATE = 'birthdate',
  DELETE_ACCOUNT = 'delete-account',
  GENDER = 'gender',
  LANGUAGE = 'language',
  NAME = 'name',
  NATIONAL_ID = 'national-id',
  PASSWORD = 'password',
  PHONE = 'phone-number',
  PREFERENCES = 'preferences',
  TP_IDP = 'external-identity-providers',
  IDENTITY = 'identity',
  EDIT_ADDRESS = 'postal-address',
  EDIT_EMAIL = 'email-addresses',
  EDIT_TP_IDP = 'external-providers',
  SECURITY = 'security',
  PROFILE = 'profile',
  SERVICES = 'services',
  DELETE_VALIDATED = 'delete-validated',
  MANAGE_DEVICE = 'manage-device',
}

export const DEFAULT_ACR = 'AA FF A B F';
export const EDIT_KEY = 'edit';

export type CardButton = {
  checkAuthorization?: EDIT_PAGE;
  href?: string;
  id: string;
  label?: string;
};
export interface IButton {
  checkAuthorization?: EDIT_PAGE;
  className?: string;
  href?: string;
  id: string;
  label: string;
  secondary?: boolean;
}

export interface CardElement {
  button?: React.JSX.Element;
  disable?: boolean;
  fullWidth?: boolean;
  key: string;
  label?: string;
  switch?: React.JSX.Element;
  value: string | React.JSX.Element | React.JSX.Element[];
  variant?: string;
}

export type CardProps = {
  button?: CardButton;
  listElements: CardElement[];
  modal?: IModalProperties;
  subtitle?: string;
  title: string;
};

export interface IModalProperties {
  content: React.JSX.Element;
  title: string;
  titleLogoUri?: string;
  isOpen?: boolean;
  subtitle?: string;
  snackBar?: {
    status?: 'info' | 'success' | 'error';
    show?: boolean;
    title?: string;
    explanation?: string;
  };
}
export interface CardPropsLegacy {
  title: string;
  log_DDRUM_Button: string;
  subtitle?: string;
  listElem?: CardElement[];
  blockContent?: JSX.Element;
  modal?: IModalProperties;
  button?: IButton;
  isSide?: boolean;
  stickBottom?: boolean;
  isApp?: boolean;
  snackExplanation?: string;
  showSnack?: boolean;
  setShowSnak?: React.Dispatch<React.SetStateAction<boolean>>;
}
