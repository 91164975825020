import useMediaQuery from '@mui/material/useMediaQuery';
import { Footer as PluxeeFooter } from '@pluxee-design-system/core';
import React from 'react';
import packageInfo from '../../../../package.json';
import { firstLetterUpperCase } from '../../../account-manager/utils/utils';
import { GlobalContentLocalizedContent } from '../../../services/localization/interfaces';

type FooterProps = {
  clientId?: string;
  globalContent: GlobalContentLocalizedContent;
  footerUrl: {
    privacy: string;
    cookies: string;
  };
};

const Footer = ({ globalContent, footerUrl }: FooterProps): JSX.Element => {
  const footerLeftElements = [];
  const footerRightElements = [];

  const isDesktop = useMediaQuery('(min-width:813px)');

  if (!isDesktop) {
    footerLeftElements.push({ children: `© Pluxee ${new Date().getFullYear()}`, key: '1' });
    footerLeftElements.push({
      children: globalContent?.privacy,
      href: footerUrl.privacy,
      key: '2',
      target: '_blank',
    });
    footerLeftElements.push({
      children: globalContent?.cookies,
      href: footerUrl.cookies,
      key: '4',
      target: '_blank',
    });
  } else {
    footerLeftElements.push({ children: `© Copyright Pluxee ${new Date().getFullYear()}`, key: '1' });
    footerLeftElements.push({
      children: firstLetterUpperCase(globalContent.privacy_policy),
      href: footerUrl.privacy,
      key: '2',
      target: '_blank',
    });
    footerLeftElements.push({
      children: firstLetterUpperCase(globalContent.cookie_policy),
      href: footerUrl.cookies,
      key: '3',
      target: '_blank',
    });
  }

  footerRightElements.push({ children: `${globalContent?.version} ${packageInfo.version}`, key: '1' });

  return <PluxeeFooter leftElements={footerLeftElements} rightElements={footerRightElements} />;
};

export default Footer;
