import React from 'react';
import JSONPretty from 'react-json-pretty';
import { ReactComponent as ThumbDown } from '../../../../public/images/thumb-down.svg';
import { FinalOpProps, PageData } from '../../../models/interfaces/interfaces';
import OpLayout from '../../components/layout/legacy/op-layout';
import NextHeader from '../../components/next-header/next-header';

export default function LegacyError({
  title,
  reason,
  reqId,
}: { title?: string; reason?: string; reqId?: string } = {}): JSX.Element {
  const LegacyOpLayoutProps = {
    globalContent: {
      privacy_policy: 'Privacy Policy',
      cookie_policy: 'Cookie Policy',
      privacy_cookies: 'Privacy & Cookies',
      version: 'version',
    },
    pageContent: {
      main_title: 'Sorry',
    },
    page: {
      data: {
        locale: 'en-US',
      },
    },
    state: [
      { activeStep: 0, cardIcon: false, className: '', showHcaptcha: false, isLoading: false, largeCard: false },
      () => {},
    ],
    footerUrl: {
      privacy:
        'https://assets.contentstack.io/v3/assets/bltb623f73452ceb473/blt47442a4e74b21b83/65d36cd077f34ca3ecca3d3e/SodexoConnect_Privacy_Policy_EN_20230411.pdf',
      cookies:
        'https://assets.contentstack.io/v3/assets/bltb623f73452ceb473/blt0e525857aac92f82/65d36cd0849f31553ee97c8f/SodexoConnect_Cookie_Policy_EN_20230411.pdf',
    },
  } as unknown as FinalOpProps<PageData, any>;

  return (
    <>
      <NextHeader title='Sorry' />
      <OpLayout data={LegacyOpLayoutProps}>
        <p style={{ marginBottom: 32 }}>{title || '404 - Page not found'}</p>
        <ThumbDown />
        {reqId && <div className='error-page-id'>{reqId}</div>}
        {reason && (
          <div className='error-page-details'>
            <div hidden={!reason}>
              Reason : <JSONPretty className='error-page-details-reason' data={reason}></JSONPretty>
            </div>
          </div>
        )}
      </OpLayout>
    </>
  );
}
