import React, { useEffect, useState } from 'react';
import LegacyError from '../front/views/errors/legacy-error';
import PluxeeError from '../front/views/errors/pluxee-error';

export default function Custom404(props: { isPluxee: boolean }): React.JSX.Element {
  const [isPluxee, setIsPluxee] = useState(props.isPluxee);

  useEffect(() => {
    if (isPluxee === undefined)
      setIsPluxee(window.location.href.includes('pluxee') || window.location.href.includes('3090'));
  }, []);

  if (isPluxee === undefined) return <></>;

  return isPluxee ? <PluxeeError text='We cannot find the page you are looking for...' /> : <LegacyError />;
}
